<template lang="pug">
  header.header(:class="{'header--open': hamburger}")
    b-container.header__container(fluid="lg")
      router-link(:to="{ name: 'Home' }")
        img.header__logo(alt="Camino logo" src="../assets/logo.png")
      div.header__hamburger(@click="hamburger = !hamburger")
        span(v-for="index in 6" :key="index")
      b-nav.header__nav
        b-nav-item.header__item(:to="{ name: 'Home' }" exact exact-active-class="active") Home
        b-nav-item.header__item(:to="{ name: 'ListeningMaterials' }" exact-active-class="active") Listening materials
        b-nav-item.header__item(
          href="https://cambridge.org/camino-al-espanol"
          target="_blank") Book info
        b-nav-item(:to="{ name: 'Credits' }" exact-active-class="active") Credits
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      hamburger: false
    }
  },
  watch: {
    '$route.path' (path) {
      if (this.hamburger) {
        this.hamburger = false
      }
    },
    hamburger: function () {
      document.body.style.overflow = this.hamburger ? 'hidden' : ''
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  background-color: #FFF;
  padding: 10px 0;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  height: 100px;
  display: flex;
  &--open{
    .header__nav{
      opacity: 1;
      transition: opacity .4s ease 0s,top 0s ease 0s;
      top: 100px;
      z-index: 9999;
      @media (min-width: 992px) {
        top: auto;
      }
    }
    .header__hamburger{
      span {
        &:nth-child(1),
        &:nth-child(6) {
          transform: rotate(45deg);
        }
        &:nth-child(2),
        &:nth-child(5) {
          transform: rotate(-45deg);
        }
        &:nth-child(1) {
          left: 5px;
          top: 10px;
        }
        &:nth-child(2) {
          left: calc(50% - 5px);
          top: 10px;
        }
        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }
        &:nth-child(4) {
          left: 100%;
          opacity: 0;
        }
        &:nth-child(5) {
          left: 5px;
          top: 25px;
        }
        &:nth-child(6) {
          left: calc(50% - 5px);
          top: 25px;
        }
      }
    }
  }
  &__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: relative;
  }
  &__link{
    position: relative;
    z-index: 99;
  }
  &__logo{
    // max-width: 200px;
    max-height: 80px;
  }
  ::v-deep .router-link-active{
    color:$--color-primary--dark;
  }
  &__nav{
    opacity: 0;
    top: -1000%;
    position: fixed;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: opacity .4s ease 0s,top 0s ease .4s;
    background-color: #FFF;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    @media (min-width: 992px) {
      flex-direction: row;
      padding-top: 0;
      background-color: transparent;
      opacity: 1;
      position: inherit;
      top: auto;
      left: auto;
      right: auto;
      height: auto;
      width: auto;
    }
  }
  &__item{
    font-size: 18px;
    @media (min-width: 992px) {
      font-size: 16px;
    }
  }
  &__hamburger {
    width: 50px;
    height: 39px;
    overflow: hidden;
    // position: absolute;
    // right: 15px;
    // z-index: 2;
    transform: rotate(0deg);
    cursor: pointer;
    @media (min-width: 992px) {
      display: none;
    }
    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 50%;
      background: $--color-primary;
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(even) {
        left: 50%;
      }
      &:nth-child(odd) {
        left: 0px;
      }
      &:nth-child(1),
      &:nth-child(2) {
        top: 0px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        top: 17px;
      }
      &:nth-child(5),
      &:nth-child(6) {
        top: 34px;
      }
    }
  }
}
</style>
