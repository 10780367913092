<template lang="pug">
  div.unit
    b-container(fluid="lg")
      b-breadcrumb
        b-breadcrumb-item(:to="{ name: 'ListeningMaterials' }") Listening materials
        b-breadcrumb-item(active) {{ unit === 22 ? 'Pronunciation guide' : 'Unidad ' + unit }}
      vue-vimeo-player(
        v-if="unit"
        :video-url="url"
        :options="{ responsive: true }")
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'Unit',
  components: { vueVimeoPlayer },
  data () {
    return {
      unit: null,
      url: 'https://vimeo.com/'
    }
  },
  created () {
    this.unit = parseInt(this.$route.params.unit)
    switch (this.unit) {
      case 1:
        this.url += '621548586/a6a5a1ef56'
        break
      case 2:
        this.url += '621548976/c5e04d3017'
        break
      case 3:
        this.url += '621592455/3603a7d530'
        break
      case 4:
        this.url += '621592517/9df35059ab'
        break
      case 5:
        this.url += '621592554/032619c84c'
        break
      case 6:
        this.url += '621592578/3a5f327560'
        break
      case 7:
        this.url += '621592624/ab0d9f7cb5'
        break
      case 8:
        this.url += '621592652/081e26b95f'
        break
      case 9:
        this.url += '621549516/fa61aab0c9'
        break
      case 10:
        this.url += '621592671/00935ecaa4'
        break
      case 11:
        this.url += '621676714/e0f29fd2e4'
        break
      case 12:
        this.url += '621676780/571ec4a098'
        break
      case 13:
        this.url += '621676845/9a47040e79'
        break
      case 14:
        this.url += '621677276/31ced8ec43'
        break
      case 15:
        this.url += '621677317/c2f2b35ec5'
        break
      case 16:
        this.url += '621677850/bb71f03a66'
        break
      case 17:
        this.url += '621678667/46719fecae'
        break
      case 18:
        this.url += '621549601/8f85cdaffd'
        break
      case 19:
        this.url += '621550045/fcc2a508b0'
        break
      case 20:
        this.url += '621550787/8af70b8723'
        break
      case 21:
        this.url += '621550885/4891fcb9bb'
        break
      case 22:
        this.url += '624721307/11a17fc93f'
        break
      default:
        this.url += ''
        break
    }
  }
}
</script>

<style scoped lang="scss">
.unit{
  padding: 16px 0;
}
</style>
