<template lang="pug">
  div.listening
    b-container(fluid="lg")
     div.listening__row
        div.listening__col(
          v-for="(unit, index) in units"
          :key="unit.id")
          router-link.listening__button(
            v-if="unit.unit"
            :to="{ name: 'Unit', params: { unit: unit.unit } }") {{ unit.name }}
          div.listening__button(v-else) {{ unit.name }}
</template>

<script>
export default {
  name: 'ListeningMaterials',
  data () {
    return {
      units: [{
        name: 'Unidad 1',
        unit: 1
      }, {
        name: 'Unidad 2',
        unit: 2
      }, {
        name: 'Unidad 3',
        unit: 3
      }, {
        name: 'Unidad 4',
        unit: 4
      }, {
        name: 'Unidad 5',
        unit: 5
      }, {
        name: 'Unidad 6',
        unit: 6
      }, {
        name: 'Unidad 7',
        unit: 7
      }, {
        name: 'Unidad 8',
        unit: 8
      }, {
        name: 'Unidad 9',
        unit: 9
      }, {
        name: 'Unidad 10',
        unit: 10
      }, {
        name: 'Unidad 11',
        unit: 11
      }, {
        name: 'Unidad 12',
        unit: 12
      }, {
        name: 'Unidad 13',
        unit: 13
      }, {
        name: 'Unidad 14',
        unit: 14
      }, {
        name: 'Unidad 15',
        unit: 15
      }, {
        name: 'Unidad 16',
        unit: 16
      }, {
        name: 'Unidad 17',
        unit: 17
      }, {
        name: 'Unidad 18',
        unit: 18
      }, {
        name: 'Unidad 19',
        unit: 19
      }, {
        name: 'Unidad 20',
        unit: 20
      }, {
        name: 'Unidad 21',
        unit: 21
      }, {
        name: 'Pronunciation guide',
        unit: 22
      }]
    }
  }
}
</script>

<style scoped lang="scss">
.listening{
  padding: 50px 0;
  &__row{
    @media (min-width: 768px) {
      display: grid;
      grid-template-rows: repeat(11, minmax(0, 1fr));
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      grid-column-gap: 30px;
    }
  }
  &__button{
    display: block;
    margin-bottom: 30px;
    background: #B4822F;
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    text-align: center;
    &:hover{
      background: #333333;
    }
  }
}
</style>
