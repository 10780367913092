import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ListeningMaterials from '../views/ListeningMaterials.vue'
import Credits from '../views/Credits.vue'
import Unit from '../views/Unit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/listening-materials',
    name: 'ListeningMaterials',
    component: ListeningMaterials
  },
  {
    path: '/unit/:unit',
    name: 'Unit',
    component: Unit
  },
  {
    path: '/credits',
    name: 'Credits',
    component: Credits
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
