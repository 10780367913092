import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { LayoutPlugin, NavPlugin, BreadcrumbPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/scss/bootstrap-theme.scss'
import '@/assets/scss/main.scss'

Vue.use(LayoutPlugin)
Vue.use(NavPlugin)
Vue.use(BreadcrumbPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
