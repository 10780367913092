<template lang="pug">
  div#app
    camino-header
    router-view
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    'camino-header': Header
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
