<template lang="pug">
  div.home
    b-container(fluid="lg")
      b-row
        b-col(cols="12" lg="4")
          img.home__book(src="@/assets/book.png")
        b-col(cols="12" lg="8")
          h1.home__title #[span Camino al español:] a comprehensive course in Spanish.
          h2.home__subtitle Listening materials to accompany the book
          p.home__description Welcome to the “Camino al español” listening materials website. Here you will find listening materials, pronunciation guides and practice exercises to help you on your journey to learn the Spanish language. The material has been recorded using a wide variety of different accents and styles of Spanish from across the globe. These listening materials are designed to be used in conjunction with the “Camino al español” language course. If you haven’t already got a copy, it is available in all good book retailers, or you can find out more on the #[a(href="https://cambridge.org/camino-al-espanol" target="_blank") Cambridge University Press website.]
          p.home__description Under the “Listening materials” tab at the top of the page, you can find exercises corresponding to each unit in the book. In the book the listening exercises are identified with a listening icon.  We advise that you look at the book, and study the vocabulary boxes provided with each activity first. You may choose to read and listen at the same time from the book, but we strongly recommend you listen to the recording at least a couple of times before looking at the audio transcripts. You may find it useful to repeat the exercise several times.
          a.home__cambridge(href="https://cambridge.org/camino-al-espanol" target="_blank")
            img.home__logo(src="@/assets/CUP_logo.png")
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped lang="scss">
.home{
  background-image: url('../assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  color: #FFF;
  text-align: center;
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    // height: calc(100vh - 100px);
    text-align: left;
  }
  &__book{
    max-width: 250px;
    margin-bottom: 30px;
    @media (min-width: 992px) {
      max-width: 100%;
      margin-bottom: 0;
    }
  }
  &__title{
    margin-bottom: 30px;
    font-size: rem(28);
    span{
      // text-transform: uppercase;
      font-size: rem(45);
      display: block;
      font-weight: 700;
      margin-bottom: 5px;
      @media (min-width: 992px) {
        font-size: rem(52);
      }
    }
  }
  &__subtitle{
    margin-bottom: 40px;
    font-size: rem(28);
  }
  &__logo{
    max-width: 240px;
  }
  &__description{
    margin-bottom: 20px;
    font-size: rem(18);
    &:last-child {
      margin-bottom: 0;
    }
    a{
      color: #FFF;
      text-decoration: underline;
    }
  }
  &__cambridge{
    margin-top: 60px;
    display: block;
  }
}
</style>
